// 3rd-party Plugins
// @import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./_metronic/_assets/sass/style.react.scss";

html {
  font-size: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: scroll;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  background: #262a41;
  font-family: Helvetica, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 1.1 !important;
  color: #333;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-bg {
  background-color: #262a41 !important;
  overflow-y: hidden;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

p {
  font-size: 14px !important;
  font-weight: 400 !important;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea {
  font-size: 16px;
  font-weight: 500;
  height: auto;
  line-height: 28px;
  background: transparent;
  box-shadow: none;
  padding: 0 15px;
  outline: none;
  border: 2px solid transparent;
  border-radius: 1px;
  color: #6f6b80;
  color: var(--color-body);
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border-color: transparent;
}

// .custom-bg-button {
//   @apply text-base text-black rounded-md bg-gray-50 transition duration-200
// }

a.btn-transparent,
button.btn-transparent {
  background-color: rgba(10, 187, 135, 0.1);
  color: #0abb87;
}

a.btn-transparent:hover,
button.btn-transparent:hover {
  background-color: #0abb87;
  color: white;
}

.btn.dropdown-toggle {
  font-size: 14px;
}

.btn.dropdown-toggle:after,
.nav-link.dropdown-toggle:after {
  text-align: center;
  display: inline;
  border: 0;
  font-size: 0.6rem;
  vertical-align: middle;
  background-color: transparent;
  position: relative;
  margin-left: 0.5rem;
  opacity: 0.7;
  font-family: Flaticon2;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  content: "";
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.popup-mobile-menu.active .inner {
  opacity: 1;
  left: 0;
  overflow-y: auto;
}

.popup-mobile-menu .inner {
  width: 320px;
  z-index: 999;
  position: absolute;
  background: #ffffff;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 0;
  left: -150px;
  transition: all 0.5s ease-out;
}

.popup-mobile-menu .inner .header-top {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.popup-mobile-menu.active .inner {
  opacity: 1;
  left: 0;
  overflow-y: hidden;
}

.popup-mobile-menu .mainmenu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 15px 20px;
}

.edu-header.header-sticky.sticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #262a41 !important;
  width: 100%;
  height: 80px;
  animation: headerSticky 0.95s ease forwards;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
  z-index: 100;
}

.right-popup-mobile-menu {
  z-index: 99;
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.right-popup-mobile-menu .right-inner {
  width: 350px;
  z-index: 100;
  position: absolute;
  background: #ffffff;
  background-color: rgb(255, 255, 255);
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 0;
  right: -150px;
  transition: all 0.1s ease-out;
}

.right-popup-mobile-menu.active .right-inner {
  opacity: 1;
  right: 0;
  overflow-y: auto;
}

.right-popup-mobile-menu.active {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1024px) {
  .kt-header__topbar--mobile-on .kt-header__topbar {
    margin-top: 60px;
    transition: all 0.3s ease;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.2);
    border-top: 1px solid #ebedf2;
  }
}

.language-popup-mobile-menu {
  z-index: 9999;
  content: "";
  position: absolute;
  top: 40px;
  right: 130px;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.language-popup-mobile-menu .language-inner {
  width: 320px;
  z-index: 999;
  position: absolute;
  background: #ffffff;
  background-color: rgb(255, 255, 255);
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  height: 100%;
  height: 200px;
  width: 170px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  right: 0;
  top: -150px;
  transition: all 0.5s ease-out;
}

.language-popup-mobile-menu.active .language-inner {
  opacity: 1;
  top: 10px;
  overflow-y: hidden;
}

.language-popup-mobile-menu.active {
  visibility: visible;
  opacity: 1;
}

// .kt-header__topbar {
//   height: 80px;
// }
// Default Layout Skins

/*.main_load{*/

/*background-image: "url('../../client/Images/loading.gif')"*/

.lessonScrollbar {
  overflow: auto;
  max-height: 100%;
}

.platformIcon:hover {
  filter: grayscale(100%);
}

.platformIcon {
  transition: filter 0.3s;
}

.lessonItem:hover > .lessonItemText {
  // background-color: #000000 !important;
  color: #ffffff !important;
  transition: color 200ms;
}

.ql-video {
  width: 100%;
  height: 450px;
}

.ql-editing {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  z-index: 1000;
  margin-top: -20px !important;
  /* Negative half of height. */
  margin-left: -160px !important;
  height: 40px !important;
  width: 320px !important;
}

#playBtnCon:hover > #playBtn {
  // background-color: #000000 !important;
  #playIcon {
    // color: #ffffff !important;
    opacity: 1;
  }
}

#playBtnCon:hover {
  cursor: pointer;
}

// #playBtn {
//   transition: background-color 0.6s;
// }
#playIcon {
  opacity: 0.8;
  transition: opacity 0.3s;
}

#walletIconHeader:hover {
  color: #ffffff !important;
}

#walletIconHeader:active:focus {
  color: #ffffff !important;
}

.pppeo::before,
.pppeo::after,
.pppeo {
  background-color: #374afb !important;
}

// /*}*/

// html {
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   overflow-y: scroll;
// }

// *,
// *::before,
// *::after {
//   -webkit-box-sizing: inherit;
//   box-sizing: inherit;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   margin-top: 0;
//   margin-bottom: 0.5rem;
// }

// .background {
//   overflow: auto;
// }

// .scene-tooltip {
//   max-width: 180px !important;
//   color: white !important;
// }

// .container-search {
//   height: 100%;
//   min-height: 100%;
//   margin-right: auto;
//   margin-left: auto;
//   background-color: rgba(255, 255, 255, 0.1);
// }

// .navColor {
//   background-color: #eee;
// }

// #meetingControls {
//   display: none;
// }
// #meetingCon:hover > #meetingControls {
//   display: flex;
// }

// //code editor edits
.tabs-content-mobile > .react-sandbox-editor36 > #tabs-content {
  width: 100% !important;
  height: 50%;
}

.tabs-content-mobile > .react-sandbox-editor36 > #tabs-content > div {
  height: 50% !important;
}

.tabs-content-mobile
  > .react-sandbox-editor36
  > #tabs-content
  > div:last-child {
  top: 50% !important;
  left: 0 !important;
  width: 100% !important;
}

#tabs-content {
  width: 65% !important;
  height: 100%;
}

#tabs-content > div:last-child {
  top: 0px !important;
  left: 100% !important;
  width: 53.5% !important;
}

#tabs-content > div {
  height: 100% !important;
}

.ace-monokai .ace_print-margin {
  width: 0px !important;
}

//code editor edits

.ace-monokai,
.ace_dark {
  font-size: 16px !important;
}

.boxWithShadow {
  -webkit-box-shadow: -1px 3px 11px -2px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: -1px 3px 11px -2px rgba(0, 0, 0, 0.7);
  box-shadow: -1px 3px 11px -2px rgba(0, 0, 0, 0.7);
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #000;
  /*Sets the text hover color on navbar*/
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: black;
  /*BACKGROUND color for active*/
  background-color: #030033;
}

.navbar-default {
  border-color: #030033;
  background-color: #eee;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: silver;
  /*Change rollover cell color here*/
}

.navbar-default .navbar-nav > li > a {
  color: #37474f;
  /*Change active text color here*/
}

.dateincomment {
  font-size: 10px;
  padding: 5px;
  display: block;
  margin-bottom: 2px;
  background-color: rgb(223, 223, 223);
}

.container-search-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: auto;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.jss36 {
  width: 100%;
  display: flex;
  padding: 6px 10px;
  text-align: center;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.bg-custom-img {
  background-image: url("./_metronic/_assets/images/bg/home-five-hiw.jpg");
}

.bottom-border-line {
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0)
    )
    left bottom no-repeat;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  background-size: 100% 1px;
}

@media (min-width: 1024px) {
  .lg\:mt-0 {
    margin-top: 0px !important;
  }
}

.lessonScrollbar {
  margin-right: 0px;
  height: calc(100vh - 60px) !important;
}

.lesson-container {
  height: calc(100vh - 70px) !important;
}

.custom-min-h {
  min-height: calc(100vh - 60px) !important;
  background-color: #fff;
}

@media only screen and (max-width: 1024px) {
  .custom-min-h {
    min-height: calc(100vh - 40px) !important;
  }
}

// .row {
//   overflow: hidden;
//   width: 1264px;
//   padding-right: 0;
//   padding-left: 0;
//   background-color: rgba(255, 255, 255, 0.2);
// }

// .rowAuth {
//   width: auto;
//   padding-right: 0;
//   padding-left: 0;
//   background-color: rgba(255, 255, 255, 0.2);
// }

// @media (max-width: 1264px) {
//   .row {
//     width: 100%;
//     margin: auto;
//     margin-top: 0;
//     background-color: rgba(255, 255, 255, 0);
//   }
// }

// @media (min-width: 1400px) {
//   .lessonScrollbar {
//     max-height: 100%;
//   }
// }
// @media (min-width: 1600px) {
//   .lessonScrollbar {
//     max-height: 100%;
//   }
// }
// @media (min-width: 2000px) {
//   .lessonScrollbar {
//     height: 100vh;
//     max-height: 100vh;
//   }
// }

// .message-textarea {
//   float: center;
//   width: 65%;
//   height: 120px;
//   border: 1px solid lightgrey;
// }

// .text-input__content {
//   display: none;
// }

// .TextCard {
//   text-align: center;
//   background-color: white;
// }

// .container-contact-up {
//   font-family: Roboto, sans-serif;
//   z-index: 1;
//   box-sizing: border-box;
//   width: 500px;
//   height: auto;
//   margin: auto;
//   padding-top: 30px;
//   padding-right: 30px;
//   padding-bottom: 10px;
//   padding-left: 90px;
//   transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
//   text-align: left;
//   color: rgba(0, 0, 0, 0.870588);
//   border-radius: 2px;
//   background-color: rgb(255, 255, 255);
//   box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 6px,
//     rgba(0, 0, 0, 0.117647) 0 1px 4px;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }

// .container-contact-down {
//   font-family: Roboto, sans-serif;
//   z-index: 1;
//   box-sizing: border-box;
//   width: 500px;
//   height: auto;
//   margin: auto;
//   margin-top: 5px;
//   padding: 50px;
//   transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
//   text-align: center;
//   color: rgba(0, 0, 0, 0.870588);
//   border-radius: 2px;
//   background-color: rgb(255, 255, 255);
//   box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 6px,
//     rgba(0, 0, 0, 0.117647) 0 1px 4px;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }

// #uploaderHTML {
//   -webkit-appearance: none;
//   appearance: none;
//   width: 50%;
//   margin: 10px;
// }

// .pageStyle {
//   width: 60%;
//   min-width: 800px;
//   margin: auto;
//   margin-bottom: 2px;
//   padding: 50px;
// }

// @media (max-width: 1250px) {
//   .pageStyle {
//     width: 100%;
//     min-width: 100%;
//     margin: auto;
//     margin-bottom: 2px;
//     padding: 50px;
//   }
// }

// .cursor-pointer {
//   cursor: pointer;
// }

// .Top {
//   display: block;
//   margin-right: auto;
//   margin-left: auto;
//   border-width: 3px;
//   border-style: inset;
//   border-color: red;
// }

// .knowledgetags {
//   text-align: left;
// }

// .navbar {
//   overflow: hidden;
//   margin-bottom: 0;
//   list-style-type: none;
// }

// .navbar > li {
//   float: right;
// }

// .Bottom {
//   margin-right: auto;
//   margin-left: auto;
//   border-style: inset;
//   border-color: red;
// }

// .button {
//   float: right;
//   overflow: hidden;
//   margin-right: 2%;
//   cursor: pointer;
//   border: none;
//   outline: none;
//   background-color: Transparent;
//   background-repeat: no-repeat;
// }

// .button1 {
//   float: left;
//   overflow: hidden;
//   /*outline:none;*/
//   width: 30%;
//   height: 90%;
//   margin-right: 2%;
//   cursor: pointer;
//   border: 4px solid grey;
//   background-color: #9099a8;
//   background-repeat: no-repeat;
// }

// .product-tab {
//   height: 88vh;
//   display: flex;
//   background-color: #f5f5f5;
//   /* Firefox */
//   /* Chrome, Safari */
// }

// .product-tab2 {
//   display: flex;
//   height: 83.5vh;
//   background-color: #f5f5f5;
//   overflow: hidden;
//   /* Firefox */
//   /* Chrome, Safari */
// }

// .left-panel {
//   padding-top: 2vh;
//   flex: 1;
//   color: black;
// }

// .right-panel {
//   padding-top: 5px;
//   flex: 1;
//   background-color: #cfd8dc;
// }

// .product-search {
//   float: left;
//   width: 300px;
//   height: auto;
//   margin-top: 5px;
//   margin-right: 5px;
//   margin-bottom: 10px;
//   margin-left: 10px;
//   text-align: center;
//   background-color: #eceff1;
// }

// .addbar {
//   margin-bottom: 5%;
// }

// h3 {
//   top: 0;
//   display: inline;
// }

// .inputfield {
//   width: 70%;
//   margin-left: 5%;
//   padding-left: 5%;
//   color: black;
//   border: solid black 2px;
//   background: #fff;
// }

// .inputfield:hover {
//   color: black;
//   border: solid black 1px;
//   -webkit-border-radius: 0;
//   -moz-border-radius: 0;
// }

// .tags {
//   margin-top: 18px;
//   margin-right: 35px;
//   margin-left: 0;
// }

// @media (max-width: 1199px) {
//   .tags {
//     margin-right: 0;
//     margin-left: 0;
//   }
// }

// .table-dark {
//   overflow-y: scroll;
//   width: 100%;
//   height: 100%;
//   opacity: 0.7;
//   background: #fff;
// }

// .table-dark::-webkit-scrollbar {
//   width: 1em;
// }

// .table-dark::-webkit-scrollbar-track {
//   background-color: #7fb0b7;
//   -webkit-box-shadow: inset 0 0 6px #7fb0b7;
// }

// .table-dark::-webkit-scrollbar-thumb {
//   background-color: #233e51;
// }

// .table-dark::-webkit-scrollbar-corner {
//   background-color: #233e51;
// }

// body {
//   margin: 0px;
//   background: #262a41;
//   font-family: Roboto, sans-serif;
//   font-weight: 300;

//   font-size: 14px;
//   font-weight: normal;
//   line-height: 1.42;
//   color: #333;
//   text-rendering: optimizeLegibility;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

p {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.1;
  color: inherit;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
  min-height: 18px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
}

p {
  margin: 0 0 10px;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f7f7f9;
  border-radius: 0.25rem;
}

a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  white-space: pre-wrap !important;
}

pre,
blockquote {
  page-break-inside: avoid;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 5px;
  font-size: 90%;
  color: #292b2c;
  background-color: #f5f5f5;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

.p-white p {
  color: white !important;
}

// kbd {
//   padding: 0.2rem 0.4rem;
//   font-size: 90%;
//   color: #fff;
//   background-color: #292b2c;
//   border-radius: 0.2rem;
// }

// kbd kbd {
//   padding: 0;
//   font-size: 100%;
//   font-weight: bold;
// }

// .loginContainer {
//   width: 50%;
//   margin: auto;
// }

// @media (min-width: 0px) and (max-width: 1000px) {
//   .loginContainer {
//     width: 100%;
//     margin: auto;
//   }
// }

// /*
// use below if wanting to change the styling of the code boxes in the side bar
.scrollbar-container
  > div
  > div
  > div
  > div
  > div
  > .quill
  > .ql-container
  > .ql-editor
  > .ql-syntax,
.scrollbar-container
  > div
  > div
  > div
  > div
  > div
  > div
  > .quill
  > .ql-container
  > .ql-editor
  > .ql-syntax {
  background-color: rgb(247, 247, 247) !important;
  color: #333333 !important;
}

.ql-syntax {
  background-color: rgb(247, 247, 247) !important;
  color: #333333 !important;
  word-break: break-word;
}

#header {
  background-color: #dae5f7;
  padding: 30px;
  border: 1px dotted grey;
  font-family: Arial, Helvetica, sans-serif;
}

#library {
  background-color: #daf7ed;
  padding: 30px;
  margin-bottom: 5px;
  border: 1px dotted grey;
  font-family: Arial, Helvetica, sans-serif;
}

// .header {
//   height: 70px;
//   padding-top: 20px;
//   text-align: center;
//   color: #000;
//   background-color: #1a237e;
// }

// .welcome-info {
//   padding-left: 3%;
//   color: black;
//   padding: 10px;
// }

// .welcomeFlexContainer {
//   margin: auto;
//   width: 500px;
// }

// .welcomeCardBoxes {
//   width: 280px;
//   height: 220px;
// }

// .welcomeCardHeader {
//   background-color: rgba(190, 190, 190, 0.2);
//   color: #efeadd;
//   text-align: center;
//   padding: 10px;
// }

// .welcomeBackground {
//   background-image: linear-gradient(-188deg, #000, #262a41);
// }

// .welcomeBoxText {
//   padding: 15px;
//   color: #eef7f5;
//   text-align: center;
// }

// .welcomeFlex {
//   display: flex;
//   margin-top: 130px;
//   position: relative;
//   flex-flow: row wrap;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-content: flex-start;
// }

// .welcomeFlexItem {
//   flex-direction: row;
//   margin: 4px;
//   align-self: flex-end;
// }

// .welcome-info-header {
//   padding-right: 3%;
//   padding-left: 3%;
//   text-align: center;
//   text-transform: capitalize;
//   background-color: none;
// }

// .documentCard {
//   padding: 15px;
//   margin-bottom: 15px;
// }

// .Productcontent {
//   height: 100%;
//   margin-right: 10%;
//   margin-left: 10%;
//   text-align: center;
// }

// .contentDownload {
//   max-width: 1200px;
//   height: calc(100% - (55px));
//   margin-top: 0;
//   margin-right: auto;
//   margin-left: auto;
//   padding-top: 0;
// }

// .uploadPage {
// }

// .uploadImage {
//   margin-top: 2%;
//   text-align: center;
// }

// @media (min-width: 0px) and (max-width: 1400px) {
//   .largeImageCreation {
//     height: 196px;
//     width: 425px;
//   }
// }

// @media (min-width: 1400px) {
//   .largeImageCreation {
//     height: 393px;
//     width: 850px;
//   }
// }

// .linkCopy {
//   padding-left: 15px;
//   padding-right: 15px;
//   margin-top: 5px;
//   margin-bottom: 5px;
//   padding: 10px;
//   background: #b2ebf2;
// }

// .contentSidebar {
//   position: fixed;
//   float: left;
//   max-width: 200px;
// }

// .contentSidebarColor {
//   background-color: #efeadd;
// }

// .contentHeader {
//   min-width: 1200px;
//   max-width: 1200px;
//   margin-top: 0;
//   margin-right: auto;
//   margin-left: auto;
//   padding-top: 0;
// }

// .contentMarkdown {
//   float: right;
//   padding-left: 4px;
//   padding-right: 4px;
//   min-width: 1015px;
//   max-width: 1015px;
//   margin-top: 0;
//   margin-right: auto;
//   margin-left: auto;
//   padding-top: 0;
// }

// @media (min-width: 0) and (max-width: 1210px) {
//   .contentSidebar {
//     display: none;
//   }
//   .contentMarkdown {
//     float: none;
//     width: 100%;
//     max-width: 100%;
//   }
// }

// .welcome-text {
//   padding-top: 50px;
//   margin-right: auto;
//   margin-left: auto;
//   text-align: center;
//   color: #fff;
//   background: rgba(255, 255, 255, 0);
// }

// .welcomeBoard {
//   width: 450px;
//   margin-left: 6%;
//   background-color: rgba(255, 255, 255, 0.85);
// }

// @media (max-width: 1200px) {
//   .welcomeBoard {
//     background: rgba(255, 255, 255, 0.85);
//   }
// }

// .sub-header {
//   width: 60%;
//   height: 10%;
//   margin-right: 5%;
//   margin-left: 5%;
//   padding-left: 3%;
//   text-align: center;
//   background-color: #e1e4e7;
// }

// .productpage {
//   font-size: 18px;
//   width: 54%;
//   height: 60%;
//   margin-right: 7%;
//   margin-left: 8%;
//   padding-left: 6%;
//   color: #fff;
//   border: 4px solid grey;
//   background-color: #9099a8;
// }

// .sidebar {
//   float: left;
//   width: 100%;
//   height: auto;
//   margin-top: 0;
//   padding-right: 5px;
//   padding-left: 5px;
//   color: black;
//   background-color: none;
// }

// .productpage-top {
//   font-size: 18px;
//   width: 54%;
//   height: 10%;
//   margin-right: 7%;
//   margin-left: 7%;
//   padding-left: 6%;
//   text-align: center;
//   color: #fff;
//   background-color: #9099a8;
// }

// .inputfield-signup1 {
//   width: 100%;
//   margin-top: 20px;
//   margin-bottom: 10px;
//   color: black;
//   border: 0;
//   border-bottom: 1px solid grey;
//   outline: 0;
//   outline-color: black;
//   background: transparent;
// }

// .inputfield-signup2 {
//   width: 90%;
//   margin-right: 5%;
//   padding-left: 3%;
//   color: black;
//   border: 0;
//   border-bottom: 2px solid black;
//   outline: 0;
//   outline-color: black;
//   background: transparent;
// }

// .rating {
//   padding-top: 1px;
//   text-align: center;
//   opacity: 0.9;
// }

// .container {
//   width: 100%;
//   margin-right: 0;
//   padding-right: 0;
//   padding-left: 0;
// }

// @media (max-width: 1199px) {
//   .sidebar {
//     padding-right: 0;
//     padding-left: 0;
//   }
// }

// @media (min-width: 1200px) {
//   .container {
//     float: left;
//     height: auto;
//     margin-left: 14%;
//   }
// }

// .container2 {
//   margin-right: 5%;
//   margin-left: 5%;
//   max-width: 850px;
//   background-color: none;
// }

// .fullHeight {
//   height: 87vh;
// }

// .itemCard {
//   width: 350px;
//   margin-top: 3%;
//   margin-right: 33%;
//   margin-left: 33%;
//   background-color: none;
// }

// .innerItemCard {
//   margin-top: 10px;
//   margin-right: 8px;
//   margin-left: 8px;
//   background-color: none;
// }

// .image-user {
//   width: 100%;
//   height: 100%;
//   background-size: cover;
// }

// .htmlsrcimage {
//   width: 100%;
//   height: 40%;
//   border: 4px solid black;
//   background-color: #000;
// }

// .headerimg {
//   float: right;
//   margin-right: 5%;
//   border-radius: 50%;
//   background-color: white;
// }

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// .fadeIn {
//   animation-name: fadeIn;
//   animation-iteration-count: 1;
//   animation-timing-function: ease-in;
//   animation-duration: 1s;
//   width: 100%;
// }

// .product_image {
//   width: 100%;
//   height: 180px;
// }

// .description {
//   font-size: 20px;
//   width: 90%;
//   height: auto;
//   margin-top: 1%;
//   margin-left: 5%;
//   padding-right: 4%;
//   color: black;
//   border-radius: 5px;
// }

// .code {
//   font-size: 15px;
//   margin-top: 2px;
//   margin-right: 5px;
//   padding-left: 5px;
//   color: black;
// }

// .code img,
// .contentMarkdown img {
//   max-width: 100%;
//   max-height: 100%;
// }

// .productdetails {
//   cursor: pointer;
// }

// .loader {
//   padding-top: 20%;
//   padding-right: 45%;
//   padding-left: 45%;
// }

// .searchLoader {
//   position: absolute;
//   right: 50%;
//   bottom: 50%;
//   margin: auto;
//   overflow: hidden;
// }

// .Userimage {
//   float: none;
//   width: 100%;
//   padding: 0 20%;
//   border: 0 solid white;
// }

// .usercommentname {
//   text-align: right;
//   /*border: 2px solid red;*/
// }

// .commentContainer {
//   margin-bottom: 10px;
//   margin-left: 16px;
//   margin-right: 16px;
// }

// .usercomments {
//   text-align: left;
//   padding-top: 6px;
//   padding-left: 8px;
//   padding-right: 8px;
//   padding-bottom: 1px;
// }

// .productcomments {
//   /*border: 2px solid red;*/
//   background: rgb(236, 236, 236);
// }

// .Product1 {
//   width: 100%;
//   min-height: 350px;
//   cursor: pointer;
//   vertical-align: middle;
//   /*border: 10px solid red;*/
// }

// .markdowncode {
//   /*border: 1px solid red;*/
//   float: left;
//   width: 50%;
//   vertical-align: middle;
//   color: black;
// }

// .markdowntext {
//   /*border: 10px solid red;*/
//   float: right;
//   /* Firefox */
//   /* Chrome, Safari */
//   overflow-y: scroll;
//   width: 50%;
//   vertical-align: middle;
//   color: black;
//   background-color: white;
// }

// .Product {
//   width: 100%;
//   height: 213px;
//   /*cursor: pointer;*/
//   vertical-align: middle;
// }

// .productimage {
//   width: 300px;
//   height: 180px;
// }

// .productimage1 {
//   width: 50%;
//   height: 200px;
// }

// .image-part {
//   width: 50%;
//   border: 10px solid red;
// }

// .itemLargeImage {
//   width: 100%;
//   height: 393px;
// }

// .text-part {
//   float: right;
//   width: 50%;
//   margin-top: 5%;
//   text-align: center;
//   /*border: 10px solid red;*/
// }

// .text-part1 {
//   float: right;
//   width: 50%;
//   margin-top: 0;
//   text-align: center;
//   /*border: 10px solid red;*/
// }

// .Profiledata {
//   font-size: 16px;
//   text-align: center;
// }

// .Profiledata1 {
//   font-size: 16px;
// }

// .createproduct {
//   text-align: center;
//   vertical-align: middle;
// }

// .productcontainer {
//   width: 100%;
//   margin-right: 0;
//   /*border: 10px solid red;*/
//   margin-left: 0;
// }

// .textarea {
//   overflow: auto;
//   width: 100%;
//   height: 95%;
//   /* Firefox */
//   /* Chrome, Safari */
//   min-height: 95%;
//   resize: none;
//   border-style: none;
//   border-color: Transparent;
//   outline: none;
//   background-color: #f5f5f5;
// }

// .productdisplayleft {
//   float: left;
//   width: 55%;
//   margin-right: 25%;
//   margin-left: 22%;
//   padding-top: 9%;
//   /*border: 4px solid red;*/
// }

// .productdisplayleft1 {
//   float: left;
//   width: 50%;
//   padding-top: 5%;
//   border: 4px solid red;
// }

// .productdisplayright {
//   float: right;
//   width: 50%;
//   /*border: 4px solid red;*/
//   margin: 0;
//   padding-top: 5%;
// }

// .productdisplayright1 {
//   float: right;
//   width: 50%;
//   margin: 0;
//   padding-top: 5%;
//   border: 4px solid red;
// }

// .textarea1 {
//   width: 100%;
//   min-height: 95%;
//   margin-top: 15px;
//   outline: none;
// }

// .usertags {
//   width: 100%;
//   height: auto;
//   min-height: 60px;
// }

// .ReactTags__tagInput {
//   width: 100%;
//   margin-top: 3%;
//   margin-bottom: 3%;
//   text-align: center;
// }

// .ReactTags__tagInputField {
//   width: 100%;
//   margin-right: 5%;
//   padding-left: 3%;
//   color: black;
//   border: 0;
//   border-bottom: 2px solid black;
//   outline: 0;
//   outline-color: black;
//   background: transparent;
// }

// .ReactTags__selected .ReactTags__tag {
//   font-size: 16px;
//   width: 30%;
//   margin-right: 3%;
//   margin-left: 3%;
//   padding-right: 5px;
//   padding-left: 5px;
//   background-color: grey;
// }

// .ReactTags__selected {
//   width: 300px;
// }

// .ReactTags__remove {
//   width: 100%;
//   color: black;
// }

// .itemPreviewMargins {
//   margin-right: 40px;
//   margin-left: 40px;
// }

// @media (max-width: 1099px) {
//   .itemPreviewMargins {
//     margin-right: 0;
//     margin-left: 0;
//   }
//   .container-search {
//     width: 100%;
//   }
//   .imagecontainer {
//     width: 100%;
//     height: 350px;
//     margin-top: 0;
//     margin-bottom: 0;
//     padding: 0;
//   }
//   .image-user {
//     width: 100%;
//     height: 100%;
//     border: 0 solid white;
//     background-size: cover;
//   }
// }

// @media (min-width: 1200px) and (max-width: 1439px) {
//   .container-search {
//     width: 100%;
//   }
//   .imagecontainer {
//     width: 100%;
//     height: 220px;
//     margin-top: 0;
//     margin-bottom: 0;
//     padding: 0;
//   }
//   .image-user {
//     width: 100%;
//     height: 100%;
//     border: 0 solid white;
//     background-size: cover;
//   }
//   .container {
//     width: 700px;
//     margin-left: 0.8%;
//   }
//   .sidebar {
//     width: 490px;
//     min-height: 900px;
//   }
// }

// @media (min-width: 1440px) and (max-width: 1679px) {
//   .container-search {
//     width: 1265px;
//   }
//   .imagecontainer {
//     width: 100%;
//     height: 300px;
//     margin-top: 0;
//     margin-bottom: 0;
//     padding: 0;
//   }
//   .image-user {
//     width: 100%;
//     height: 100%;
//     border: 0 solid white;
//     background-size: cover;
//   }
//   .container {
//     width: 800px;
//     margin-left: 4%;
//   }
//   .sidebar {
//     width: 520px;
//     min-height: 900px;
//   }
// }

// @media (min-width: 1680px) and (max-width: 1919px) {
//   .container-search {
//     width: 1265px;
//   }
//   .imagecontainer {
//     width: 100%;
//     height: 280px;
//     margin-top: 0;
//     margin-bottom: 0;
//     padding: 0;
//   }
//   .image-user {
//     width: 100%;
//     height: 100%;
//     border: 0 solid white;
//     background-size: cover;
//   }
//   .container {
//     width: 830px;
//     margin-left: 12%;
//   }
//   .sidebar {
//     width: 520px;
//     min-height: 900px;
//   }
// }

// @media (min-width: 1900px) {
//   .container-search {
//     width: 1265px;
//   }
//   .imagecontainer {
//     width: 100%;
//     height: 350px;
//     margin-top: 0;
//     margin-bottom: 0;
//     padding: 0;
//   }
//   .image-user {
//     width: 100%;
//     height: 100%;
//     border: 0 solid white;
//     background-size: cover;
//   }
//   .container {
//     width: 850px;
//     margin-left: 15%;
//   }
//   .sidebar {
//     width: 550px;
//     margin-right: 5%;
//   }
// }

// /** Stripe button css **/

// .stripe-connect {
//   display: inline-block;
//   height: 30px;
//   margin-bottom: 1px;
//   padding: 1px;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   text-decoration: none;
//   border: 0;
//   -webkit-border-radius: 4px;
//   -moz-border-radius: 4px;
//   border-radius: 4px;
//   background-image: -webkit-linear-gradient(#28a0e5, #015e94);
//   background-image: -moz-linear-gradient(#28a0e5, #015e94);
//   background-image: -ms-linear-gradient(#28a0e5, #015e94);
//   background-image: linear-gradient(#28a0e5, #015e94);
//   -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
//   -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
//   box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
//   -webkit-font-smoothing: antialiased;
// }

// .stripe-connect:hover,
// .stripe-connect:active {
//   text-decoration: none;
// }

// .stripe-connect span {
//   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//   font-size: 14px;
//   font-weight: bold;
//   line-height: 30px;
//   position: relative;
//   display: block;
//   height: 30px;
//   padding: 0 12px 0 44px;
//   color: white;
//   -webkit-border-radius: 3px;
//   -moz-border-radius: 3px;
//   border-radius: 3px;
//   background: #1275ff;
//   background-image: -webkit-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
//   background-image: -moz-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
//   background-image: -ms-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
//   background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
//   -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
//   -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
//   box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
//   text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
// }

// .stripe-connect span:before {
//   position: absolute;
//   top: 50%;
//   left: 11px;
//   display: block;
//   width: 23px;
//   height: 24px;
//   margin-top: -12px;
//   content: "";
//   background-repeat: no-repeat;
//   background-size: 23px 24px;
// }

// .stripe-connect:active {
//   background: #005d93;
// }

// .stripe-connect:active span {
//   color: #eee;
//   background: #008cdd;
//   background-image: -webkit-linear-gradient(#008cdd, #008cdd 85%, #239adf);
//   background-image: -moz-linear-gradient(#008cdd, #008cdd 85%, #239adf);
//   background-image: -ms-linear-gradient(#008cdd, #008cdd 85%, #239adf);
//   background-image: linear-gradient(#008cdd, #008cdd 85%, #239adf);
//   -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
//   -moz-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
//   box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
// }

// .stripe-connect.light-blue {
//   background: #b5c3d8;
//   background-image: -webkit-linear-gradient(#b5c3d8, #9cabc2);
//   background-image: -moz-linear-gradient(#b5c3d8, #9cabc2);
//   background-image: -ms-linear-gradient(#b5c3d8, #9cabc2);
//   background-image: linear-gradient(#b5c3d8, #9cabc2);
//   -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
//   -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
//   box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
// }

// .stripe-connect.light-blue span {
//   color: #556f88;
//   background: #f0f5fa;
//   background-image: -webkit-linear-gradient(#f0f5fa, #e4ecf5 85%, #e7eef6);
//   background-image: -moz-linear-gradient(#f0f5fa, #e4ecf5 85%, #e7eef6);
//   background-image: -ms-linear-gradient(#f0f5fa, #e4ecf5 85%, #e7eef6);
//   background-image: linear-gradient(#f0f5fa, #e4ecf5 85%, #e7eef6);
//   -webkit-box-shadow: inset 0 1px 0 #fff;
//   -moz-box-shadow: inset 0 1px 0 #fff;
//   box-shadow: inset 0 1px 0 #fff;
//   text-shadow: 0 1px rgba(255, 255, 255, 0.8);
// }

// .stripe-connect.light-blue:active {
//   background: #9babc2;
// }

// .stripe-connect.light-blue:active span {
//   color: #556f88;
//   background: #d7dee8;
//   background-image: -webkit-linear-gradient(#d7dee8, #e7eef6);
//   background-image: -moz-linear-gradient(#d7dee8, #e7eef6);
//   background-image: -ms-linear-gradient(#d7dee8, #e7eef6);
//   background-image: linear-gradient(#d7dee8, #e7eef6);
//   -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05);
//   -moz-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05);
//   box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05);
//   text-shadow: 0 1px rgba(255, 255, 255, 0.8);
// }

// .stripe-connect.dark {
//   background: #252525;
//   background: rgba(0, 0, 0, 0.5) !important;
// }

// /* Admin css */

// .admin {
//   margin-top: 1rem;
// }

// .admin .action-container .status-toggle {
//   margin-top: 5px;
// }

// .admin .action-container .raised-btn {
//   width: 100%;
// }

// .policyCard {
//   padding-top: 1%;
//   padding-right: 10%;
//   padding-left: 10%;
// }

// .sideLink {
//   text-decoration: none;
// }

// .backgroundImage {
//   position: absolute;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url(https://firebasestorage.googleapis.com/v0/b/rscriptmarket-66f49.appspot.com/o/statics%2Ffront_page.jpg?alt=media&token=da6f7138-bb1d-46b3-936d-d2bfa278e01c);
//   background-repeat: no-repeat;
//   background-position: right top;
//   background-size: cover;
// }

// //hold btn
// @-webkit-keyframes fill {
//   to {
//     background-size: 0 100%;
//     color: #ffffff;
//   }
// }

// @keyframes fill {
//   to {
//     background-size: 0 100%;
//     color: #ffffff;
//   }
// }

// .cnh_holding button {
//   background: -webkit-linear-gradient(#613aea, #ff2868) #ff2868 no-repeat 0 0;
//   background: linear-gradient(#613aea, #ff2868) #ff2868 no-repeat 0 0;
//   color: #ffffff !important;
//   mix-blend-mode: multiply;
//   background-size: 100% 100%;
//   -webkit-animation: fill 1.5s forwards;
//   animation: fill 1.5s forwards;
//   animation-direction: reverse;
// }

.blury-layer {
  z-index: 10;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

p.p-lg {
  font-size: 1.25rem !important;
}

/* prose customize */
.prose {
  @apply max-w-7xl;
}

.prose pre {
  @apply border border-gray-200 text-gray-400 bg-transparent;
}

.prose strong {
  @apply text-gray-700;
}

.prose a {
  @apply text-yellow-700;
}

.propse p {
  @apply overflow-hidden;
}

.prose h4,
.prose h4 strong,
.prose h3,
.prose h3 strong,
.prose h2,
.prose h2 strong,
.prose h1,
.prose h1 strong {
  @apply text-black;
}

.prose code {
  @apply px-1 py-0.5;
}

.prose img {
  @apply m-0;
}

.prose > :first-child {
  margin-top: 1.25em !important;
  margin-bottom: 1.25em !important;
}

code[class*="language-"],
pre[class*="language-"] {
  @apply text-gray-500;
}

cite {
  display: none !important;
}

/* prose customize */

.custom-signout-button {
  width: 112px;
  text-transform: uppercase;
  transition: ease-in-out;
  transition-duration: 200ms;
  font-weight: 600;
  font-size: 11px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(224, 231, 255);
  color: rgb(99, 102, 241);
  border-radius: 5px;
}

.custom-signout-button:hover {
  background-color: rgb(99, 102, 241);
  color: rgb(224, 231, 255);
}
