/*-----------------------------
    Blog Comments Style  
------------------------------*/


.comment-list-wrapper {
    .comment {
        display: flex;
        @media #{$large-mobile} {
            flex-direction: column;
            .comment-content {
                margin-top: 20px;
            }
        }
        .thumbnail {
            min-width: 70px;
            width: 70px;
            max-height: 70px;
            border-radius: 100%;
            margin-right: 25px;
            img {
                border-radius: 100%;
                width: 100%;
            }
        }
        .comment-content {
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 0;
            }
            .date {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                color: var(--color-body);
                display: block;
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 20px;
            }

            .reply-btn {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                i {
                    margin-right: 10px;
                }
            }
        }

        & + .comment {
            border-top: 1px solid #EEEEEE;
            padding-top: 30px;
            margin-top: 30px;
        }
    }
}






/*-----------------------------
    Blog Comments Form
------------------------------*/
.comment-form-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .blog-page-title {
        margin-bottom: 0;
    }
    .cancle-btn {
        a {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            transition: 0.3s;
            &:hover {
                color: var(--color-primary);
            }
        }
    }
}



.comment-form-style-1 {

    .comment-note {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    input,
    textarea {
        background: #F5F5F5;
        border-radius: 5px; 
        border: 0 none;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        border: 1px solid transparent;
        &:focus {
            border-color: var(--color-primary);
        }
    }
    input {
        padding: 0 30px;
        height: 60px;
        line-height: 60px;
    }
    textarea {
        min-height: 180px;
        padding: 17px 30px;
    }
}


/*--------------------------------
    Blog Style Pagination 
---------------------------------*/ 
.blog-pagination-list {
    background-color: #F5F5F5;
    border-radius: 5px;
    background-image: inherit;
    transition: 0.4s;
    position: relative;
    z-index: 2;
    &::after {
        position: absolute;
        content: "";
        background-image: url(../../../images/bg/bg-image-28.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition: 0.4s;
        border-radius: 5px;
    }
    a {
        display: flex;
        align-items: center;
        padding: 30px;
        position: relative;
        z-index: 1;
        color: var(--color-body);
        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            transition: 0.4s;
            position: relative;
            z-index: 2;
        }
        i {
            font-size: 20px;
            transition: 0.4s;
            margin-right: 20px;
        }
        &::before {
            position: absolute;
            content: "";
            background:#2D284E;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0;
            transition: 0.4s;
            opacity: 0;
            border-radius: 5px;
        }
    }
    &:hover {
        &::after {
            opacity: 1;
        }
        a {
            color: var(--color-white);
            &::before {
                opacity: 0.79;
            }
        }
    }

    &.next-post {
        text-align: right;
        a {
            i {
                margin-right: 0;
                margin-left: 20px;
            }
        }
    }


    &.style-variation-2 {
        transition: 0.4s;
        border-bottom: 2px solid transparent;
       
        a {
            color: var(--color-heading);
            &::before {
                display: none;
            }
        }
        &::after {
            display: none;
        }
        &:hover {
            background: #FFFFFF;
            border-color: var(--color-primary);
            box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.06);
        }
    }
}