//
// Page
//




// Page body
body {
	background: $kt-page-body-bg-color;

	// Container 
	.kt-container {
		.kt-container {
			width: 100%;
			padding: 0 0;
		}
	}
}

// Desktop(1024px and above)
@include kt-desktop() {
	// Container 
	.kt-container {
		padding: 0 0;
	}

	// Body
	.kt-body {
		> .kt-container {
			padding-right: 0 !important;
		}
	}
}

// Page fluid mode(below $kt-page-container-width)
@media (min-width: #{$kt-page-container-width + (2 * $kt-page-fluid-container-padding) - 1px}) {
	// Page container
	.kt-container.kt-container--fluid {
		padding: 0 0;
	}
}

// Fixed container width change to fluid
@include kt-media-range( lg, $kt-page-container-width + (2 * kt-get($kt-page-padding, desktop)) ) {
	.kt-container:not(.kt-container--fluid) {
		width: 100%;
	}
}

// Tablet & mobile mode(1024px and below)
@include kt-tablet-and-mobile() {
	// Page container
	.kt-container {
		padding: 0 0;
	}

	// Body
	.kt-body {
		> .kt-container {
			padding: 0;
		}
	}

	// Page wrapper
	.kt-wrapper {
		// Fixed Header
		.kt-header-mobile--fixed & {
			padding-top: kt-get($kt-header-config, base, mobile, self, default, height);
		}
	}
}