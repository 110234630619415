/*-------------------------
    Team Styles  
--------------------------*/

.team-area-shape-position {
    position: relative;
    .shape-wrapper {
        .shape {
            position: absolute;
            &.shape-1 {
                bottom: 86px;
                right: 5%;
            }
            &.shape-2 {
                top: 130px;
                left: 136px;
            }
            &.shape-3 {
                top: 148px;
                right: 88px;
            }
            &.shape-4 {
                bottom: 115px;
                left: 127px;
            }
        }
    }
}


.team-share-info {
    margin: -3px;
    a {
        width: 34px;
        height: 34px;
        background: var(--color-white);
        line-height: 38px;
        text-align: center;
        border-radius: 100%;
        font-size: 14px;
        color: var(--color-primary);
        margin: 3px;
        transition: 0.4s;
        &:hover {
            background: var(--color-primary);
            color: var(--color-white);
        }
    }
   
}



.edu-instructor-1 {
    text-align: center;
    .edu-instructor {
        border-radius: 100%;
        position: relative;
        z-index: 2;
        transition: 0.3s;
        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: url(../../../images/team/border-shape.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            width: 100%;
            height: 100%;
            transition: 0.5s all;
        }
        .inner {
            position: relative;
            padding: 20px;
            .thumbnail {
                a {
                    display: block;
                    position: relative;
                    &::after {
                        background: var(--color-dark);
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        opacity: 0;
                        transition: all .3s cubic-bezier(.645,.045,.355,1);
                    }
                    img {
                        width: 100%;
                        border-radius: 100%;
                    }
                }
            }
            .team-share-info {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                z-index: 2;
                a {
                    display: inline-block;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .45s cubic-bezier(.23,.88,.34,.99);
                    transform: translateY(8px) scale(.8);
                    &:nth-child(1) {
                        transition-delay: .07692s;
                    }
                    &:nth-child(2) {
                        transition-delay: .15385s;
                    }
                    &:nth-child(3) {
                        transition-delay: .23077s;
                    }
                }
            }
        }
    }
    .edu-instructor-info {
        margin-top: 25px;
        .title {
            margin-bottom: 5px;
        }
        .desc {
            display: inline-block;
            color: var(--color-body);
            font-weight: 500;
            font-size: 16px;
            transition: 0.4s;
        }
    }
    &:hover {
        .edu-instructor {
            &::after {
                transform: rotate(90deg);
            }
            .team-share-info {
                a {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
            .inner {
                .thumbnail {
                    a {
                        &::after {
                            opacity: 0.4;
                        }
                    }
                }
            }
        }
        .edu-instructor-info {
            .desc {
                color: var(--color-primary);
            }
        }
    }
}


.edu-instructor-2 {
    text-align: center;
    .edu-instructor {
        border-radius: 100%;
        position: relative;
        z-index: 2;
        transition: 0.3s;
        .inner {
            position: relative;
            .thumbnail {
                a {
                    display: block;
                    position: relative;
                    &::after {
                        background: linear-gradient(180deg, rgba(45, 40, 78, 0) 0.01%, rgba(45, 40, 78, 0) 15.58%, rgba(45, 40, 78, 0.75) 100%);
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        opacity: 0;
                        transition: all .3s cubic-bezier(.645,.045,.355,1);
                        border-radius: 5px;
                        border-radius: 185px 185px 5px 5px;
                    }
                    img {
                        width: 100%;
                        border-radius: 185px 185px 5px 5px;
                        transition: all .3s cubic-bezier(.645,.045,.355,1);
                    }
                }
            }
            .team-share-info {
                position: absolute;
                z-index: 2;
                bottom: 40px;
                width: 100%;
                text-align: center;
                a {
                    width: 40px;
                    height: 40px;
                    line-height: 43px;
                    display: inline-block;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .45s cubic-bezier(.23,.88,.34,.99);
                    transform: translateY(8px) scale(.8);

                    &:nth-child(1) {
                        transition-delay: .07692s;
                    }
                    &:nth-child(2) {
                        transition-delay: .15385s;
                    }
                    &:nth-child(3) {
                        transition-delay: .23077s;
                    }
                    &:nth-child(4) {
                        transition-delay: .31077s;
                    }
                }
            }
        }
    }
    .edu-instructor-info {
        margin-top: 25px;
        .title {
            margin-bottom: 5px;
        }
        .desc {
            display: inline-block;
            color: var(--color-body);
            font-weight: 500;
            font-size: 16px;
            transition: 0.4s;
        }
    }
    &:hover {
        .edu-instructor {
            .team-share-info {
                a {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
            .inner {
                .thumbnail {
                    a {
                        &::after {
                            border-radius: 5px;
                            opacity: 1;
                        }
                        img {
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
        .edu-instructor-info {
            .desc {
                color: var(--color-primary);
            }
        }
    }
}

.edu-instructor-3 {
    text-align: center;
    .edu-instructor {
        border-radius: 100%;
        position: relative;
        z-index: 2;
        transition: 0.3s;
        .inner {
            position: relative;

            .thumbnail {
                a {
                    display: block;
                    position: relative;
                    img {
                        width: 100%;
                        border-radius: 5px;
                    }
                }
            }

            .edu-instructor-info {
                position: absolute;
                z-index: 2;
                bottom: -20px;
                width: 100%;
                text-align: left;
                background: #fff;
                box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
                border-radius: 5px;
                padding: 25px 30px;
                opacity: 0;
                transition: 0.4s;
                left: 40px;
                @media #{$sm-layout} {
                    width: calc(100% - 30px);
                }
                .title {
                    margin-bottom: 0;
                    font-size: 20px;
                    line-height: 32px;
                    font-weight: 700;
                }

                .desc {
                    display: inline-block;
                    color: var(--color-body);
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    transition: 0.4s;
                    margin-bottom: 15px;
                }
                .team-share-info {
                    a {
                        width: 40px;
                        height: 40px;
                        line-height: 43px;
                        display: inline-block;
                        transition: all .45s cubic-bezier(.23,.88,.34,.99);
    
                        &:nth-child(1) {
                            transition-delay: .07692s;
                        }
                        &:nth-child(2) {
                            transition-delay: .15385s;
                        }
                        &:nth-child(3) {
                            transition-delay: .23077s;
                        }
                        &:nth-child(4) {
                            transition-delay: .31077s;
                        }
                    }

                    &.bg-transparent {
                        margin: -12px;
                        a {
                            background: transparent;
                            color: var(--color-primary);
                            width: auto;
                            height: auto;
                            line-height: inherit;
                            margin: 12px;
                        }
                    }
                }
            }
        }
    }
   
    &.eduvibe-hover-active {
        .edu-instructor {
            .inner {
                .edu-instructor-info {
                    opacity: 1;
                    left: 20px;
                    @media #{$sm-layout} {
                        left: 0;
                    }
                }
            }
        }
    }
    &.edu-instructor-hover-visible {
        &:hover {
            .edu-instructor-info {
                opacity: 1;
                left: 20px;
                @media #{$sm-layout} {
                    left: 0;
                }
            }
        }
    }
}










